<template>
  <div class="agreement">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <div class="mian">
        <img src="~img/ecology/01.png" class="bg-img" />
        <img src="~img/ecology/02.png" class="logo-img" />
        <div class="fromlist">
          <h5>加入生态伙伴计划</h5>
          <div class="fege"></div>
          <h6>
            欢迎加入安谋科技生态伙伴计划，请填写下方申请表。提交成功且内部评估后，会有安谋科技工作人员与您联系
          </h6>
          <ul class="input_list">
            <li>
              <span>* 公司名称 :</span>
              <el-input v-model="companyName" placeholder=""></el-input>
            </li>
            <li>
              <span>* 申请人姓名 :</span>
              <el-input v-model="applicantName" placeholder=""></el-input>
            </li>
            <li>
              <span>* 公司网站 :</span>
              <el-input v-model="companyWebsite" placeholder=""></el-input>
            </li>
            <li>
              <span>* 申请人职位 :</span>
              <el-input v-model="applicantPosition" placeholder=""></el-input>
            </li>
            <li>
              <span>* 地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址 :</span>
              <el-input v-model="address" placeholder=""></el-input>
            </li>
            <li>
              <span>* 电子邮件 :</span>
              <el-input v-model="email" placeholder=""></el-input>
            </li>
            <li>
              <span>* 电话号码 :</span>
              <el-input v-model="phone" placeholder=""></el-input>
            </li>
          </ul>
          <ul class="option_list">
            <li v-for="(item, index) in list" :key="item.questionId">
              <p>{{ item.questionName }}</p>
              <el-checkbox-group v-model="checked[index]" @change="changeCheck(index)">
                <el-checkbox
                  v-for="check in item.options"
                  :key="check.optionId"
                  :label="check.optionId"
                  :class="check.optionTxt == '其它' ? 'other' : ''"
                >
                  {{ check.optionTxt }}
                  <el-input
                    @focus="inputChange(check, index)"
                    @blur="blurInput(check, index)"
                    v-model="check.otherTxt"
                    v-if="check.optionTxt == '其它'"
                  >
                  </el-input>
                </el-checkbox>
              </el-checkbox-group>
            </li>
          </ul>
          <div class="uploder_box">
            <h6>上传文件：</h6>
            <p>-可上传公司Logo文件（建议ai,ps等格式的源文件）</p>
            <p>-其他补充资料等</p>
            <transition name="el-zoom-in-bottom">
              <div class="upload_box" v-show="fileList.length <= 0">
                <el-upload
                  class="upload-demo"
                  ref="uploadbox"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="handleChange"
                  :before-upload="beforeUpload"
                  action="#"
                  multiple
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    请选择上传文件，限制每个50MB以内
                  </div>
                </el-upload>
                <p>
                  + 请选择上传文件，限制每个50MB以内
                  <span>（支持压缩包、ai、ps、cdr、ppt、word、excel、pdf、png、jpg等格式）</span>
                </p>
              </div>
            </transition>
            <transition name="el-zoom-in-top">
              <div class="add_upload_box" v-show="fileList.length > 0">
                <ul>
                  <li v-for="(val, index) in fileRenderList" :key="val.uid">
                    <div class="left">
                      <!-- <img src="../../assets/img/ecology/08.png" /> -->
                      <img :src="val.fileTypeSrc" />
                      <!-- {{fileTypecm(val)}} -->
                      <p class="p1 one-txt-cut">{{ val.name }}</p>
                      <p class="p2">{{ val.size }} 上传完成</p>
                    </div>
                    <div class="delete" @click="remove(index)">删除</div>
                  </li>
                  <li @click="uploadClick">
                    <i class="el-icon-plus"></i>
                  </li>
                </ul>
              </div>
            </transition>
            <div class="agreement">
              <el-checkbox v-model="statust"
                >* 通过提交此表单，我已阅读并同意<span @click="agreement">安谋科技生态伙伴计划协议</span>中的条款，安谋科技将根据我们的<span
                  @click="privacy"
                  >隐私政策</span
                >处理您的信息
              </el-checkbox>
              <el-checkbox @change="changeAgreement" v-model="statustow">同意接收来自安谋科技的营销邮件(可退订)</el-checkbox>
            </div>
          </div>
        </div>
        <button class="sub" @click="checkdFrom">提交</button>
        <div class="dialog" v-show="showDialog">
          <p class="er">提示：{{ errorTextl }}</p>
          <button @click="showDialog = !showDialog">确定</button>
        </div>
        <div class="dialog" v-show="showUploadDialog">
          <p class="er">提示：{{ errorTextl }}</p>
          <button @click="removeFile">确定</button>
        </div>
      </div>

      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <div class="header">
        <img src="~img/ecology/29.png" class="bg-img" />
        <img src="~img/ecology/07.png" class="logo-img" />
        <div class="fromlist">
          <h5>加入生态伙伴计划</h5>
          <div class="fege"></div>
          <h6>
            欢迎加入安谋科技生态伙伴计划，请填写下方申请表。提交成功且内部评估后，会有安谋科技工作人员与您联系
          </h6>
          <ul class="input_list">
            <li>
              <span>* 公司名称 :</span>
              <el-input v-model="companyName" placeholder=""></el-input>
            </li>
            <li>
              <span>* 公司网站 :</span>
              <el-input v-model="companyWebsite" placeholder=""></el-input>
            </li>
            <li>
              <span>* 申请人职位 :</span>
              <el-input v-model="applicantPosition" placeholder=""></el-input>
            </li>
            <li>
              <span>* 地&emsp;&emsp;址 :</span>
              <el-input v-model="address" placeholder=""></el-input>
            </li>
            <li>
              <span>* 电子邮件 :</span>
              <el-input v-model="email" placeholder=""></el-input>
            </li>
            <li>
              <span>* 电话号码 :</span>
              <el-input v-model="phone" placeholder=""></el-input>
            </li>
            <li>
              <span>* 申请人姓名 :</span>
              <el-input v-model="applicantName" placeholder=""></el-input>
            </li>
          </ul>
          <ul class="option_list">
            <li v-for="(item, index) in list" :key="item.questionId">
              <p>{{ item.questionName }}</p>
              <el-checkbox-group v-model="checked[index]" @change="changeCheck(index)">
                <el-checkbox v-for="check in item.options" :key="check.optionId" :label="check.optionId">
                  {{ check.optionTxt }}
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 5 }"
                    @focus="inputChange(check, index)"
                    @blur="blurInput(check, index)"
                    v-model="check.otherTxt"
                    v-if="check.optionTxt == '其它'"
                  >
                  </el-input>
                </el-checkbox>
              </el-checkbox-group>
            </li>
          </ul>
          <div class="uploder_box">
            <h6>上传文件：</h6>
            <p>-可上传公司Logo文件（建议ai,ps等格式的源文件）</p>
            <p>-其他补充资料等</p>
            <transition name="el-zoom-in-bottom">
              <!-- v-show="fileList.length <= 0" -->
              <div class="upload_box" v-show="fileList.length <= 0">
                <el-upload
                  class="upload-demo"
                  ref="uploadbox"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="handleChange"
                  :before-upload="beforeUpload"
                  action="#"
                  multiple
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    请选择上传文件，限制每个50MB以内
                  </div>
                </el-upload>
                <p>+ 请选择上传文件，限制每个50MB以内</p>
              </div>
            </transition>
            <transition name="el-zoom-in-top">
              <!-- v-show="fileList.length > 0" -->
              <div class="add_upload_box" v-show="fileList.length > 0">
                <ul>
                  <li v-for="(val, index) in fileRenderList" :key="val.uid">
                    <div class="left">
                      <img :src="val.fileTypeSrc" />
                      <p class="p1 one-txt-cut">{{ val.name }}</p>
                      <p class="p2">{{ val.size }} 上传完成</p>
                    </div>
                    <div class="delete" @click="remove(index)">删除</div>
                  </li>
                  <li @click="uploadClick">
                    <i class="el-icon-plus"></i>
                  </li>
                </ul>
              </div>
            </transition>
            <p class="file-type">（支持压缩包、ai、ps、cdr、ppt、word、excel、pdf、png、jpg等格式文件上传）</p>
            <div class="agreement">
              <el-checkbox v-model="statust"
                >* 通过提交此表单，我已阅读并同意<span @click="agreement">安谋科技生态伙伴计划协议</span>中的条款，安谋科技将根据我们的<span
                  @click="privacy"
                  >隐私政策</span
                >处理您的信息
              </el-checkbox>
              <el-checkbox @change="changeAgreement" v-model="statustow">同意接收来自安谋科技的营销邮件(可退订)</el-checkbox>
            </div>
          </div>
        </div>
        <div class="btn_box">
          <button class="sub" @click="checkdFrom">提交</button>
        </div>
        <div class="foot">
          <web-footer />
        </div>
      </div>
      <div class="dialog" v-show="showDialog">
        <p class="er">提示：{{ errorTextl }}</p>
        <button @click="showDialog = !showDialog">确定</button>
      </div>
      <div class="dialog" v-show="showUploadDialog">
        <p class="er">提示：{{ errorTextl }}</p>
        <button @click="removeFile">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: '',
      equipment: '',
      only: true,
      showFooter: true,
      list: [],
      checked: {},
      // inputValue: {},
      questionOPtions: [],
      fileList: [],
      companyName: '', //公司名称
      applicantName: '', // 申请人姓名
      companyWebsite: '', // 公司网站
      applicantPosition: '', // 申请职位
      address: '', // 地址
      email: '', // 电子邮件
      phone: '', // 电话号码
      statust: false, // 是否同意
      status: 0,
      statustow: false, // 营销邮件
      errorTextl: '', // 错误提示
      showDialog: false, // 弹窗
      showUploadDialog: false,
      fileRenderList: [],
      fileTypeSrc: '', // 文件类型图片
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    document.title = '生态伙伴计划'
    this.getFromData()
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
  },
  computed: {},
  methods: {
    inputChange(check, index) {
      if (this.checked[index].indexOf(check.optionId) == -1) {
        this.checked[index].push(check.optionId)
        this.changeCheck(index)
      }
    },
    blurInput(check, index) {
      if (!check.otherTxt) {
        let idx = this.checked[index].indexOf(check.optionId)
        this.checked[index].splice(idx, 1)
        this.changeCheck(index)
      }
    },
    beforeUpload(file) {
      console.log(file)
    },
    handleChange(file, fileList) {
      let size = (file.size / 1024 / 1024).toFixed(2)
      if (size > 50) {
        this.errorTextl = '文件大于50MB'
        this.showUploadDialog = true
        this.$refs.uploadbox.clearFiles()
        return
      }
      this.fileList = fileList
      this.fileRenderList = JSON.parse(JSON.stringify(fileList))
      this.fileRenderList.forEach((item) => {
        let fileName = item.name
        let fileTypes = fileName.substring(fileName.lastIndexOf('.'))
        if (fileTypes === '.txt') {
          item.fileTypeSrc = require('../../assets/img/ecology/08.png')
        } else if (fileTypes === '.zip' || fileTypes === '.rar' || fileTypes === '.7z') {
          item.fileTypeSrc = require('../../assets/img/ecology/20.png')
        } else if (fileTypes === '.psd') {
          item.fileTypeSrc = require('../../assets/img/ecology/21.png')
        } else if (fileTypes === '.ai') {
          item.fileTypeSrc = require('../../assets/img/ecology/22.png')
        } else if (fileTypes === '.cdr') {
          item.fileTypeSrc = require('../../assets/img/ecology/23.png')
        } else if (fileTypes === '.ppt' || fileTypes === '.pptx') {
          item.fileTypeSrc = require('../../assets/img/ecology/24.png')
        } else if (fileTypes === '.xls' || fileTypes === '.xlsx') {
          item.fileTypeSrc = require('../../assets/img/ecology/25.png')
        } else if (fileTypes === '.pdf') {
          item.fileTypeSrc = require('../../assets/img/ecology/26.png')
        } else if (fileTypes === '.jpg' || fileTypes === '.jpeg' || fileTypes === '.png' || fileTypes === '.gif') {
          item.fileTypeSrc = require('../../assets/img/ecology/27.png')
        } else if (fileTypes === '.docx') {
          item.fileTypeSrc = require('../../assets/img/ecology/28.png')
        } else {
          item.fileTypeSrc = require('../../assets/img/ecology/08.png')
        }
        if (item.size > 1000000) {
          item.size = (item.size / 1024 / 1024).toFixed(2) + 'MB'
        } else {
          item.size = (item.size / 1024).toFixed(2) + 'KB'
        }
      })
      console.log(this.fileList)
    },
    removeFile() {
      this.showUploadDialog = false
    },
    uploadClick() {
      this.$refs['uploadbox'].$refs['upload-inner'].handleClick()
    },
    changeAgreement(val) {
      this.status = val === true ? '1' : '0'
    },
    async getFromData() {
      const res = await this.axios.get('/v1/api/get_plan')
      if (res.code == '00') {
        this.list = res.data
        this.list.forEach((item, index) => {
          this.$set(this.checked, index, [])
          item.checkedList = []
        })
        console.log(this.list)
      }
    },
    changeCheck(index) {
      // console.log("111");
      this.list[index].checkedList = this.checked[index]
      // this.setoptions();
    },
    async submint() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let fd = new FormData()
      fd.append('questionOPtions', JSON.stringify(this.questionOPtions))
      fd.append('companyName', this.companyName)
      fd.append('applicantName', this.applicantName)
      fd.append('companyWebsite', this.companyWebsite)
      fd.append('applicantPosition', this.applicantPosition)
      fd.append('address', this.address)
      fd.append('email', this.email)
      fd.append('phone', this.phone)
      fd.append('status', this.status)
      this.fileList.forEach((item) => {
        fd.append('files', item.raw)
      })
      const res = await this.axios.post('/v1/api//submit_plan', fd, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      })
      console.log(res)
      if (res.code == '00') {
        loading.close()
        this.errorTextl = '提交成功'
        this.showDialog = true
        this.list.forEach((item, index) => {
          this.$set(this.checked, index, [])
          item.checkedList = []
          item.options.forEach((val) => {
            if (val.otherTxt) {
              val.otherTxt = ''
            }
          })
        })
        this.companyName = '' //公司名称
        this.applicantName = '' // 申请人姓名
        this.companyWebsite = '' // 公司网站
        this.applicantPosition = '' // 申请职位
        this.address = '' // 地址
        this.email = '' // 电子邮件
        this.phone = '' // 电话号码
        this.statust = false // 是否同意
        this.statustow = false // 营销邮件
        this.status = 0
        this.fileRenderList = []
        this.fileList = []
        this.$refs.uploadbox.clearFiles()
      } else {
        this.errorTextl = res.msg
        this.showDialog = true
      }
    },
    // 整合选项数据
    setoptionsTxt() {
      this.questionOPtions = []
      let OPLIST = JSON.parse(JSON.stringify(this.list))
      for (let i = 0; i < OPLIST.length; i++) {
        if (OPLIST[i].checkedList.length > 0) {
          OPLIST[i].intersection = OPLIST[i].options.filter((val) => {
            return OPLIST[i].checkedList.indexOf(val.optionId) !== -1
          })
        } else {
          this.errorTextl = '请选择（' + OPLIST[i].questionName + '）'
          this.showDialog = true
          return
        }
      }
      OPLIST.forEach((item) => {
        item.optionslist = []
        if (item.intersection) {
          item.intersection.forEach((val) => {
            if (val.optionTxt === '其它') {
              item.optionslist.push({
                optionId: val.optionId,
                otherTxt: val.otherTxt,
              })
            } else {
              item.optionslist.push({
                optionId: val.optionId,
              })
            }
          })
        }
      })
      OPLIST.forEach((item) => {
        if (item.optionslist) {
          this.questionOPtions.push({
            questionId: item.questionId,
            options: item.optionslist,
          })
        }
      })
      if (this.statust) {
        this.submint()
      } else {
        this.errorTextl = '请选择同意条款'
        this.showDialog = true
      }
      console.log(this.questionOPtions)
    },
    checkdFrom() {
      if (!this.companyName) {
        this.errorTextl = '请填写公司名称'
        this.showDialog = true
        return
      }
      if (!this.applicantName) {
        this.errorTextl = '请填写申请人姓名'
        this.showDialog = true
        return
      }
      if (!this.companyWebsite) {
        this.errorTextl = '请填写公司网站'
        this.showDialog = true
        return
      }
      if (!this.applicantPosition) {
        this.errorTextl = '请填写申请人职位'
        this.showDialog = true
        return
      }
      if (!this.address) {
        this.errorTextl = '请填写地址'
        this.showDialog = true
        return
      }
      if (!this.email) {
        this.errorTextl = '请填写电子邮件'
        this.showDialog = true
        return
      } else if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.email)) {
        this.errorTextl = '请输入正确的邮箱'
        this.showDialog = true
        return
      }
      if (!this.phone) {
        this.errorTextl = '请填写电话号码'
        this.showDialog = true
        return
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
        this.errorTextl = '请输入正确的手机号码'
        this.showDialog = true
        return
      }
      this.setoptionsTxt()
      // !/^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/.test(this.phone)
    },
    remove(index) {
      this.fileList.splice(index, 1)
      this.fileRenderList.splice(index, 1)
    },
    agreement() {
      let routeUrl = this.$router.resolve({
        path: '/agreement',
      })
      window.open(routeUrl.href, '_blank')
    },
    privacy() {
      let routeUrl = this.$router.resolve({
        path: '/privacy',
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.agreement {
  .pc {
    background-image: linear-gradient(#fff, #000 10%);
    .mian {
      background-color: rgba(0, 0, 0, 0.6);
      min-height: 7.08rem;
      padding-top: 3.23rem;
      .bg-img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .logo-img {
        position: absolute;
        right: 0.75rem;
        top: 2.86rem;
        width: 0.38rem;
      }
      .fromlist {
        padding-left: 1.35rem;
        .fege {
          width: 1.16rem;
          height: 0.03rem;
          margin-top: 0.24rem;
          background: #00b3d6;
        }
        h5 {
          font-size: 0.4rem;
          color: #fff;
          font-weight: bold;
          font-family: Microsoft YaHe;
        }
        h6 {
          font-size: 0.2rem;
          color: #fff;
          margin-top: 0.47rem;
          margin-bottom: 0.2rem;
          font-weight: bold;
        }
        .input_list {
          width: 16.37rem;
          // background: hotpink;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 48%;
            height: 0.8rem;
            margin-bottom: 0.1rem;
            // background-color: skyblue;
            border-bottom: 1px solid #575757;
            span {
              font-size: 0.2rem;
              color: #fff;
              line-height: 1rem;
            }
            .el-input {
              width: 80%;
              /deep/.el-input__inner {
                height: 0.4rem;
                background: transparent;
                border: none;
                color: #fff;
              }
            }
          }
        }
        .option_list {
          width: 16.37rem;
          margin-top: 0.84rem;
          li {
            p {
              font-size: 0.2rem;
              color: #fff;
              margin-bottom: 0.33rem;
            }
            .el-checkbox-group {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 0.2rem;
              /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
              .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                background-color: #00b3d6;
                border-color: #00b3d6;
              }
              .el-checkbox {
                width: 20%;
                margin-bottom: 0.5rem;
                margin-right: 0;
                color: #fff;
                display: flex;
                align-items: center;
                /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
                  color: #00b3d6;
                }
                /deep/.el-checkbox__label {
                  width: 100%;
                }
              }
              .other {
                width: 40%;
                .el-input {
                  width: 85%;
                  // opacity: 0;
                  /deep/.el-input__inner {
                    border: none;
                    background: transparent;
                    border-bottom: 1px solid #575757;
                  }
                }
              }
            }
          }
        }
        .uploder_box {
          h6 {
            font-size: 0.22rem;
          }
          p {
            font-size: 0.2rem;
            color: #fff;
            line-height: 0.38rem;
          }
          .upload_box {
            width: 16.41rem;
            min-height: 0.69rem;
            background-color: #2a2a2a;
            border: 1px solid #5a5a5a;
            position: relative;
            margin-top: 0.21rem;
            .upload-demo {
              /deep/.el-upload {
                width: 100%;
                background: skyblue;
                height: 0.69rem;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              }
            }
            p {
              font-size: 0.22rem;
              line-height: 0.69rem;
              margin-left: 0.29rem;
              span {
                font-size: 0.2rem;
                color: #919090;
              }
            }
          }
          .add_upload_box {
            width: 16.41rem;
            min-height: 1.21rem;
            margin-top: 0.21rem;
            background-color: #2a2a2a;
            border: 1px solid #5a5a5a;
            padding: 0.15rem;
            padding-right: 0;
            padding-bottom: 0;
            ul {
              display: flex;
              flex-wrap: wrap;
              justify-content: start;
              li {
                width: 24%;
                margin-right: 0.15rem;
                margin-bottom: 0.15rem;
                height: 0.87rem;
                border-radius: 0.1rem;
                background: #0c0c0c;
                padding-left: 0.19rem;
                padding-right: 0.3rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                .left {
                  width: 70%;
                  img {
                    float: left;
                    width: 0.36rem;
                    margin-right: 0.12rem;
                  }
                  p {
                    line-height: 0.2rem;
                  }
                  .p1 {
                    font-size: 0.14rem;
                    color: #c2c2c2;
                  }
                  .p2 {
                    font-size: 0.14rem;
                    color: #939393;
                  }
                }
                .delete {
                  color: #939393;
                  font-size: 0.16rem;
                  cursor: pointer;
                }
                i {
                  font-size: 0.3rem;
                  color: #4f4f4f;
                  font-weight: bolder;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                .upload-demo {
                  /deep/.el-upload {
                    width: 100%;
                    background: skyblue;
                    height: 0.87rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                  }
                }
              }
              li:last-child {
                cursor: pointer;
              }
            }
          }
        }
        .agreement {
          margin-top: 0.84rem;
          margin-bottom: 0.72rem;
          .el-checkbox {
            margin-bottom: 0.5rem;
            font-size: 0.2rem;
            display: block;
            /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: #00b3d6;
              border-color: #00b3d6;
            }

            /deep/.el-checkbox__input {
              .el-checkbox__inner {
                width: 0.2rem;
                height: 0.2rem;
              }
              .el-checkbox__inner::after {
                height: 0.1rem;
                left: 0.06rem;
                width: 0.05rem;
              }
            }
            /deep/.el-checkbox__label {
              font-size: 0.2rem;
              color: #fff;
              span {
                color: #00b3d6;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      .sub {
        width: 2.93rem;
        height: 0.59rem;
        color: #fff;
        background: #00b3d6;
        margin: auto;
        display: block;
        margin-bottom: 0.97rem;
        font-size: 0.2rem;
        border-radius: 0.1rem;
        cursor: pointer;
      }
    }
    .dialog {
      width: 4.82rem;
      height: 2.07rem;
      background: #f8f8f8;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 0.1rem;
      padding-top: 0.64rem;
      z-index: 99;
      p {
        font-size: 0.2rem;
        text-align: center;
      }
      button {
        width: 2.93rem;
        height: 0.59rem;
        background: #00b3d6;
        border-radius: 0.1rem;
        display: block;
        margin: auto;
        margin-top: 0.4rem;
        color: #fff;
        font-size: 0.2rem;
        cursor: pointer;
      }
    }
  }
  .web {
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    overflow: hidden;
    position: relative;
    .header {
      padding-top: 150px;
      .logo-img {
        width: 60%;
        float: right;
        margin-right: 20px;
      }
      .bg-img {
        position: absolute;
        top: 0;
        left: 0;
      }
      .fromlist {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        .fege {
          width: 116px;
          height: 3px;
          margin-top: 14px;
          background: #00b3d6;
        }
        h5 {
          font-size: 20px;
          color: #fff;
          font-family: Microsoft YaHe;
        }
        h6 {
          font-size: 11px;
          color: #fff;
          margin-top: 22px;
          margin-bottom: 20px;
          font-weight: bold;
          line-height: 19px;
        }
        .input_list {
          // background: hotpink;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 100%;
            height: 40px;
            margin-bottom: 10px;
            // background-color: skyblue;
            border-bottom: 1px solid #575757;
            padding-top: 15px;
            span {
              font-size: 12px;
              color: #fff;
              letter-spacing: 3px;
              // line-height: 40px;
            }
            .el-input {
              width: 60%;
              /deep/.el-input__inner {
                height: 20px;
                background: transparent;
                border: none;
                color: #fff;
                line-height: 20px;
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }
        .option_list {
          margin-top: 40px;
          li {
            margin-bottom: 150px;
            position: relative;
            p {
              font-size: 13px;
              color: #fff;
              // margin-bottom: 20px;
            }
            .el-checkbox-group {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;
              align-items: flex-end;
              /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
              .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                background-color: #00b3d6;
                border-color: #00b3d6;
              }
              .el-checkbox {
                width: 50%;
                margin-top: 20px;
                margin-right: 0;
                color: #fff;
                position: static;
                /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
                  color: #00b3d6;
                }
                /deep/.el-checkbox__label {
                  font-size: 12px;
                  font-weight: 400;
                }
              }

              .el-textarea {
                width: 95%;
                position: absolute;
                bottom: -120px;
                left: 0;
                border-radius: 20px;
                /deep/.el-textarea__inner {
                  border: none;
                  background: #272727;
                  color: #fff;
                }
              }
            }
          }
        }
        .uploder_box {
          padding-top: 8px;
          h6 {
            font-size: 12px;
            margin-bottom: 10px;
          }
          p {
            font-size: 12px;
            color: #fff;
            line-height: 28px;
          }
          .upload_box {
            background-color: #2a2a2a;
            border: 1px solid #5a5a5a;
            position: relative;
            margin-top: 0.21rem;
            height: 50px;
            .upload-demo {
              /deep/.el-upload {
                width: 100%;
                background: skyblue;
                height: 50px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              }
            }
            p {
              font-size: 14px;
              line-height: 0.69rem;
              margin-left: 0.29rem;
              text-align: center;
              line-height: 50px;
            }
          }
          .add_upload_box {
            margin-top: 10px;
            background-color: #2a2a2a;
            border: 1px solid #5a5a5a;
            padding: 10px;
            ul {
              display: flex;
              flex-wrap: wrap;
              justify-content: start;
              li {
                width: 100%;
                height: 62px;
                border-radius: 10px;
                background: #0c0c0c;
                padding-left: 11px;
                padding-right: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                margin-bottom: 14px;
                .left {
                  width: 70%;
                  img {
                    float: left;
                    width: 32px;
                    margin-right: 9px;
                  }
                  p {
                    font-size: 12px;
                    line-height: 12px;
                  }
                  .p1 {
                    font-size: 12px;
                    color: #c2c2c2;
                  }
                  .p2 {
                    font-size: 12px;
                    color: #939393;
                    line-height: 25px;
                  }
                }
                .delete {
                  color: #939393;
                  font-size: 12px;
                  cursor: pointer;
                }
                i {
                  font-size: 20px;
                  color: #cccccc;
                  font-weight: bolder;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                .upload-demo {
                  /deep/.el-upload {
                    width: 100%;
                    background: skyblue;
                    height: 0.87rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                  }
                }
              }
              li:last-child {
                cursor: pointer;
              }
            }
          }
          .file-type {
            font-size: 10px;
            color: #919090;
            line-height: 16px;
            margin-top: 15px;
          }
        }
        .agreement {
          margin-top: 40px;
          margin-bottom: 40px;
          .el-checkbox {
            margin-bottom: 20px;
            font-size: 12px;
            display: block;
            /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: #00b3d6;
              border-color: #00b3d6;
            }
            /deep/.el-checkbox__input {
              vertical-align: top;
            }
            /deep/.el-checkbox__label {
              white-space: initial;
            }
            // /deep/.el-checkbox__input {
            //   .el-checkbox__inner {
            //     width: 0.2rem;
            //     height: 0.2rem;
            //   }
            //   .el-checkbox__inner::after {
            //     height: 0.1rem;
            //     left: 0.06rem;
            //     width: 0.05rem;
            //   }
            // }
            /deep/.el-checkbox__label {
              font-size: 12px;
              color: #fff;
              span {
                color: #00b3d6;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
      .btn_box {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
      .sub {
        width: 100%;
        height: 40px;
        color: #fff;
        background: #00b3d6;
        margin: auto;
        display: block;
        margin-bottom: 0.97rem;
        font-size: 12px;
        border-radius: 5px;
      }
      .foot {
        position: relative;
        padding-bottom: 10px;
        background-color: #000;
      }
    }
    .dialog {
      width: 281px;
      height: 124px;
      background: #f8f8f8;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      padding-top: 36px;
      z-index: 99;
      p {
        font-size: 12px;
        text-align: center;
      }
      button {
        width: 147px;
        height: 30px;
        background: #00b3d6;
        border-radius: 10px;
        display: block;
        margin: auto;
        margin-top: 30px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
</style>
